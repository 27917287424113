/***************************************************
 * TODO 
 *  - ❌ redo checkbox logic  
 ***************************************************/
import React, {Fragment, useState, useEffect} from "react"
import {ApplyLicenseModal, ApplyLicensesModal} from './modal/ApplyMultipleLicenses'
import { deleteMember } from '../actions/member'
import { useDispatch,  } from 'react-redux'
import { Redirect } from "react-router-dom"
import UpdateMember from './modal/UpdateMember';
import ModalWrapper from './modal/ModalWrapper';
import ApplyLicense from './modal/ApplyLicense';
import {Button, Grid, Typography, Toolbar, Checkbox, List, ListItem, ListItemText, TableContainer, TableBody, TableCell, TableHead, TableRow, Paper, Table } from "@material-ui/core"
import TablePagination from '@material-ui/core/TablePagination';
import Drawer from '@material-ui/core/Drawer';
import UpdateMembersDrawer from './updateDrawer'
import moment from 'moment'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import TableSortLabel from '@material-ui/core/TableSortLabel';


/*******************************************************
 * @description Disply a cell with sorting label
 * @params 
 *  - text: text to display
 *  - onClick: Handle sorting
 *  - isActive: is cell sorting active
 *  - direction: sort label direction
 * @todo 
*******************************************************/
const TableCellWithSort = ({text, onClick, isActive, direction }) => {
  return (
    <TableCell > 
      <TableSortLabel
        active={isActive}
        direction={direction}
        onClick={onClick}
      >
        {text} 
      </TableSortLabel> 
    </TableCell>
  )
}

/*******************************************************
 * @description heler function to generate the props for
 * a cell is selected
 * @params 
 *  - text: cell name to check against sort label
 * @todo 
*******************************************************/
const isCellSorted = (cellName, activeSortLabel, sortOrder) => {
  return {
    isActive: activeSortLabel === cellName,
    direction: activeSortLabel === cellName ? sortOrder : 'asc'
  }
}

const MemberRow = ({member, selected, onSelect}) => {
  const [editMemberOpen, setOpen] = useState(false)

  return(
    <TableRow hover key={member.clinic_id} selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox 
          onChange={onSelect}
          checked={selected}
        />
      </TableCell>
      <TableCell>
        <List>
          <ListItem alignItems="flex-start" style={{"flexDirection": "column"}}>
            <ListItemText primary={member.clinic_id} secondary={'Clinic Id'}></ListItemText>
            <ListItemText primary={member.account_number} secondary={'Account Number'}></ListItemText>
          </ListItem>
        </List>
      </TableCell>
      <TableCell>
        <List>
          <ListItem alignItems="flex-start" style={{"flexDirection": "column", "maxWidth": "70%"}}>
            <ListItemText
              primary={member.clinic_name}
              secondary={
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                >
                  {member.street1 + ' ' + member.street2 + ', ' + member.city + ', ' + member.zip}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </TableCell>
      <TableCell>
        <List>
          <ListItem alignItems="flex-start" style={{"flexDirection": "column"}}>
          <ListItemText
            secondary={
              <Typography
                component="div"
                variant="body2"
                color="textPrimary"
              >
                Notifications: {member.notification_emails}
              </Typography>
            }
            />
          </ListItem>
        </List>
      </TableCell>
      <TableCell >{ member.customer ? member.customer.name : 'Does not belong to a customer'}</TableCell>
        <TableCell >
          <List>
            <ListItem alignItems="flex-start" style={{"flexDirection": "column"}}>
            <ListItemText 
              primary={
                member.license ? 'Active License' : 
                <ModalWrapper
                  title="Apply License"
                  buttonText="Apply License"
                  width={800}
                  showOk={false}
                  component={ApplyLicense}
                  member={member}
                />
              }
              secondary={
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                >
                  { !member.license || !member.license.activation_date ?  "" : 
                    moment.utc(member.license.activation_date).format('MMM D YYYY') + ' - ' + moment.utc(member.license.expiration_date).format('MMM D YYYY')
                  }
                </Typography>
              }
            />
          </ListItem>
         </List>
        </TableCell>
        <TableCell>
          <Button color="primary" onClick={() => setOpen(true)}>Quick Edit</Button>
          <Drawer anchor="right" open={editMemberOpen} variant="persistent">
            {editMemberOpen && <UpdateMember  member={member} onClose={() => setOpen(false)}/>}
          </Drawer>
        </TableCell>
      </TableRow>
  )
}

const MembersTableContent = ({page, rowsPerPage, members, onSort}) => {
  const [selectedRows, setSelected ] = useState(new Set())
  const [redirectToSyncPage, setRedirectToSyncPage] = useState(false)

  const [activeSortLabel, setActiveSort] = useState('')
  const [sortOrder, setSortOrder] = useState('desc')

  const onSelectSort = (selectedLabel) => {
    setSortOrder(onSort(selectedLabel, sortOrder))
    setActiveSort(selectedLabel)
  }

  const onRedirectToSync = () => {
    try {
      localStorage.setItem('syncClinicIds', JSON.stringify([...selectedRows]))
    } catch (err) {
      console.error("Error saving to local state: ", err)
    }
    setRedirectToSyncPage(true)
  }

  const onClickCheckbox = (id) => {
    let rows = new Set(selectedRows)
    selectedRows.has(id) ? rows.delete(id) : rows.add(id)
    if(rows.has('all')) {
      rows.delete('all')
    }
    setSelected(rows)
  }

  const onClickAllCheckBox = () => {
    let rows = new Set(selectedRows)
    if(rows.has('all'))  {
      setSelected(new Set()) 
    } else {
      members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( ({clinic_id}) => {
        rows.add(clinic_id)
      })
      rows.add('all')
      setSelected(rows)
    }
  }

  if(redirectToSyncPage)
    return <Redirect to="/admin/syncMembers" />

  //cache calculation
  const sliceStart = page * rowsPerPage
  const sliceEnd = sliceStart + rowsPerPage
  return(
      <Fragment>
        <TableContainer >
          <Table size="small" className="wcn-table" aria-label="members tabel">
            <TableHead className="wcn-table__head">
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={onClickAllCheckBox}
                    checked={selectedRows.has('all')}
                  />
                </TableCell>
                <TableCellWithSort 
                  text="Clinic ID"
                  onClick={() => onSelectSort("clinic_id")}
                  {...isCellSorted("clinic_id", activeSortLabel, sortOrder )}
                />
                <TableCellWithSort 
                  text="Clinic"
                  onClick={() => onSelectSort("clinic_name")}
                  {...isCellSorted("clinic_name", activeSortLabel, sortOrder )}
                />
                <TableCell>Email</TableCell>
                <TableCell>Customer</TableCell>
                <TableCellWithSort 
                  text="License"
                  onClick={() => onSelectSort("license_id")}
                  {...isCellSorted("license_id", activeSortLabel, sortOrder )}
                />
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.slice(sliceStart, sliceEnd).map(member => <MemberRow member={member} key={member.clinic_id + 'row'} selected={selectedRows.has(member.clinic_id)} onSelect={() => onClickCheckbox(member.clinic_id)}/>)}
            </TableBody>
          </Table>
        </TableContainer>
        <MembersTableFooter 
          members={members.filter( ({clinic_id}) => selectedRows.has(clinic_id) )} 
          onRedirect={onRedirectToSync} 
        />
    </Fragment>
  )
}

const MembersTableFooter = ({onRedirect, members}) => {
  const dispatch = useDispatch()

  const onDeleteMembers = () => {
    for(const {_id} of members) {
      dispatch(deleteMember(_id))
    }
    //setSelected(new Set())
  }

  return(
    <Drawer anchor="bottom" open={members.length !== 0} variant="persistent">
      <Grid container justify="center" alignitems="center" style={{padding: "1rem"}}>
        <Grid item xs={1}><Typography variant="caption" style={{color: "grey"}}> {members.length} selected </Typography></Grid>
        <Grid item xs={11} >
          <Grid container justify="center" spacing={3}>
            <ApplyLicensesModal members={members} displayBtn={true}/> 
            <UpdateMembersDrawer members={members} />
            <Button color="primary" onClick={onRedirect} >Sync with Sycle API</Button>
            <Button onClick={onDeleteMembers} color="primary">Delete</Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}

const TablePaginationActions = ( { count, page, rowsPerPage, onChangePage }) => {
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{display: 'flex'}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight /> 
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}


const MembersTable = ({members, onSort}) => {
  const [page, setPage ] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const onChangePage = page => {
    setPage(page)
  }

  /*----------------------------------------------------------
   * If the member list changes (thru sorting or searching etc..)
   * reset page back to 0 to display newest results
  ----------------------------------------------------------*/
  useEffect( () => {
    setPage(0)   
  }, [members])

  const onChangeRowsPerPage = value => {
    const newLength = value === -1 ? members.length : Number(value)
    const newMaxPage = Math.floor(members.length / newLength)
    setRowsPerPage(newLength)
    if(page > newMaxPage) //set to last page
      setPage(newMaxPage)
  }

  return(
    <Fragment>
      <Typography variant="caption" style={{color: "grey"}}>{members.length} Members found. Page {page} of {Math.floor(members.length / rowsPerPage)}</Typography>
      <Paper style={{marginTop: "10px", marginBottom: "20px"}}>
        <Toolbar><Typography variant="h6" id="MemberTitle"> Members </Typography></Toolbar>
        <MembersTableContent rowsPerPage={rowsPerPage} page={page} members={members} onSort={onSort} />
        <TablePagination
            rowsPerPageOptions={[20, 30, 50, 100, {label: 'All', value: -1}]}
            component="div"
            count={members.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(e, v) => onChangePage(v)}
            onChangeRowsPerPage={e => onChangeRowsPerPage(e.target.value)} 
            ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Fragment>
  )
}

export default MembersTable
