import React, { useEffect, useState, useContext, Fragment } from "react"
import StateContext from '../../MemberContext'
import LoadingBar from '../../uitools/LoadingBar'
import { handleLogin, fetchClinics, AppointmentTypesSycle } from '../../syncComponents/helpers'
import axios from "axios"

const AddSycleMember = ({handleSubmit, handleInputChange, customers}) => {
    const [err, setErr] = useState(null)
    const [token, setToken] = useState(null)
    const [fetching, setFetching ] = useState(false)
    const {memberInfo, setMemberInfo} = useContext(StateContext);

    const onHandleLogin = () => {
        if(!memberInfo.username) {
            setErr("Missing username")
        } else if(!memberInfo.password) {
            setErr("Missing password")
        } else if(!memberInfo.api_endpoint) {
            setErr("Missing api endpoint")
        } 
        else {
            setErr(null)
            setFetching(true)
            handleLogin(memberInfo).then ( login_response => {
                let res = login_response[0].data.token
                if(res === "") {
                    throw("Error Fetching token. No token returned")
                }
                setFetching(false)
                setToken(res)
            }).catch( (err) => {
                setFetching(false)
                console.log(err)
                setErr("Failed logging in please try again")
            })
        }
    }
    return(
        <form onSubmit={handleSubmit} className="c-form">
            <LoadingBar isLoading={fetching} />
            {err && <span className="c-form__error">{err}</span>}
            <div className="c-form__form-group">
                <label> Sycle Username</label>
                <input 
                    type="text" 
                    name="username" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.username} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Sycle Password </label>
                <input 
                    type="text" 
                    name="password" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.password} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Api Endpoint</label>
                <input 
                    type="text" 
                    name="api_endpoint" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.api_endpoint} 
                />
            </div>
            {/*<div className="c-form__form-group">
                <label>Known Zip (Optional)</label>
                <input
                    type="text"
                    name="known_zip"
                    className="form-control"
                    onChange={handleInputChange}
                    value={memberInfo.known_zip}
                />
            </div>*/}
            <div className="c-form__form-group">
                <label>Known Clinic ID (Optional)</label>
                <input
                    type="text"
                    name="known_clinic_id"
                    className="form-control"
                    onChange={handleInputChange}
                    value={memberInfo.known_clinic_id}
                />
            </div>
            {!token && 
                <div className="c-form__controls">
                    <button onClick={onHandleLogin} type="button" className="c-btn">Login </button>
                </div>
            }
            {token && <AddSycleClinic token={token} handleSubmit={handleSubmit} customers={customers} handleInputChange={handleInputChange}/> }
            {memberInfo.clinic_id && <AddSycleMemberForm customers={customers} handleInputChange={handleInputChange} />}
        </form>
    )
}


const AddMultipleClinics = (memberInfo, clinics, handleSubmit, customers) => {
    clinics.map( ({clinic}) => {
        handleSubmit(null, {
            ...memberInfo,
            ...clinic.address,
            street1: clinic.address.street2,
            phone: clinic.phone1,
            clinic_id:  clinic.clinic_id, 
            clinic_name: clinic.clinic_name,
        })
    })
}

const AddSycleClinic = ({token, handleSubmit, customers, handleInputChange}) => {
    const {memberInfo, setMemberInfo} = useContext(StateContext);
    const [clinics, setClinics ] = useState(null)
    const [filteredClinics, setFilteredClinics] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [err, setErr] = useState(null)
    const [appointmentTypes, setAppointmentTypes] = useState(null)
    const [addAllClincs, setAddAll] = useState(false)

    useEffect( () => {
        setFetching(true)
        let params = {
            params: {
                token: token,
                clinic_id: memberInfo['known_clinic_id'] ? [ memberInfo.known_clinic_id ] : ['all'],
                api_endpoint: memberInfo.api_endpoint
            }
        }

        /*
        if (memberInfo['known_zip']) {
            params.params.proximity = {
                zip: memberInfo['known_zip'],
                miles: 100
            }
        }
        */

        axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/clinics', params).then( response => {
            setFetching(false)
            setErr(null)
            
            // If we got a single clinic back, we need to format as an array
            if (!Array.isArray(response.data.clinic_details)) {
                setClinics([ response.data.clinic_details ]);
                setFilteredClinics([ response.data.clinic_details ]);
            } else {
                setClinics(response.data.clinic_details)
                setFilteredClinics(response.data.clinic_details)
            }
        }).catch ( err => {
            setFetching(false)
            console.log("Error fetching the clinics: ", err)
            setErr("Error fetching the clinics. Please check the logs")
        })
    }, [])

    const handleClinicChange = (e) => {
        e.preventDefault();
        let target = e.target;
        let value = target.value;
        value = JSON.parse(value)
        setMemberInfo({
            ...memberInfo, 
            ...value.clinic.address,
            phone: value.clinic.phone1,
            clinic_id:  value.clinic_id, 
            clinic_name: value.clinic.clinic_name
        })
        setAppointmentTypes(value.appointment_types)
    }

    const AddAllClinics = () => {
        AddMultipleClinics(memberInfo, clinics, handleSubmit, customers)
    }

    return (
        <div className="c-form__form-group">
            <LoadingBar isLoading={fetching} />
            {err && <span className="c-form__error">{err}</span>}
            {!filteredClinics && !err && <label> Fetching clinics...</label>}
            {filteredClinics && <label> Select a clinic </label> }
            {filteredClinics && <ClinicsFilter clinics={clinics} setFilteredClinics={setFilteredClinics} />}
            {filteredClinics &&
                <select 
                    name="clinic" 
                    className="form-control"
                    onChange={handleClinicChange}
                    defaultValue="0"
                >
                    <option value="0">Please Select a Clinic</option>
                    {filteredClinics.map( ({appointment_types, clinic: {clinic_id, ...clinic}}) => 
                        <option key={clinic_id} value={JSON.stringify({appointment_types, clinic, clinic_id})}>{clinic.clinic_name}</option>
                    )}
                </select>
            }
            {filteredClinics && <button className="c-btn" type="button" onClick={(e) => {e.preventDefault(); setAddAll(true)}}> Add All Clinics</button>}
            {filteredClinics && addAllClincs && memberInfo.customer_id !=='' && <button className="c-btn" type="button" onClick={AddAllClinics}> Add All Now</button>}
            {addAllClincs && 
                <div className="c-form__form-group">
                    <label>select the customer to add them too</label>
                    <select 
                        name="customer_id" 
                        className="form-control"
                        onChange={handleInputChange}
                        defaultValue="0"
                    >
                        <option value="0" disabled>Choose Customer</option>
                        {!customers && <span> Loading customers </span>}
                        { customers && customers.map(customer => {
                            return (
                                <option 
                                    key={customer._id}
                                    value={customer._id}
                                >
                                    {customer.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            }
            {appointmentTypes && <AppointmentTypesSycle appointmentTypes={appointmentTypes} />}
        </div>
    )
}

const AddSycleMemberForm = ({customers, handleSubmit, handleInputChange}) => {
    const {memberInfo, setMemberInfo} = useContext(StateContext);
    let currCustomers = customers || []

    return (
        <Fragment>
            <div className="c-form__form-group">
                <label>Clinic Name</label>
                <input 
                    type="text" 
                    name="clinic_name" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.clinic_name} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Account Number</label>
                <input 
                    type="text" 
                    name="account_number" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.account_number} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Customer</label>
                <select 
                    name="customer_id" 
                    className="form-control"
                    onChange={handleInputChange}
                    defaultValue="0"
                >
                    <option value="0" disabled>Choose Customer</option>
                    { currCustomers.map(customer => {
                        return (
                            <option 
                                key={customer._id}
                                value={customer._id}
                            >
                                {customer.name}
                            </option>
                        )
                    })}
                </select>
            </div>

            <div className="c-form__form-group">
                <label>Email</label>
                <input 
                    type="text" 
                    name="email" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.email} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Notification Emails</label>
                <input 
                    type="text" 
                    name="notification_emails" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.notification_emails} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 1 </label>
                <input 
                    type="text" 
                    name="street1" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street1} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 2 </label>
                <input 
                    type="text" 
                    name="street2" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street2} 
                />
            </div>
            <div className="c-form__form-group">
                <label> City </label>
                <input 
                    type="text" 
                    name="city" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.city} 
                />
            </div>
            <div className="c-form__form-group">
                <label> State </label>
                <input 
                    type="text" 
                    name="state" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.state} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Zip </label>
                <input 
                    type="text" 
                    name="zip" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.zip} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Phone </label>
                <input 
                    type="text" 
                    name="phone" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.phone} 
                />

            </div>

            <div className="c-form__controls">
                <button type="submit" className="c-btn">Create</button>
            </div>
        </Fragment>
    )
}

const ClinicsFilter = ({clinics, setFilteredClinics}) => {
    const [inputFilter, setInput] = useState(null)

    useEffect( () => {
        if(inputFilter === "") {
            setFilteredClinics(clinics)
        }
        if(inputFilter) {
            setFilteredClinics(clinics.filter( ({clinic: {clinic_id}}) => clinic_id.includes(inputFilter) ))
        }
    }, [inputFilter])
    return(
        <input 
            type="text" 
            name="inputFilter" 
            className="form-control" 
            onChange={(e) => setInput(e.target.value)} 
            value={inputFilter} 
            placeholder="Search by id..."
        />
    )
}

export default AddSycleMember